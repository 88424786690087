// Cards.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import cardsData from '../datas/cards.json';
import '../styles/cards.scss';

function Cards({ setProjectDetails, modalOpen, openModal }) {
    const [jsonData] = useState(cardsData);
    const [projectTitle, setProjectTitle] = useState('');
    const [triggerFetch, setTriggerFetch] = useState(false);

    useEffect(() => {
        if (!projectTitle) return;

        const fetchData = async () => {
            const apiURL = `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_URLAPI}projects?sqlfilters=title:=:'${projectTitle}'`;
            try {
                const response = await axios.get(apiURL, {
                    headers: { "DOLAPIKEY": process.env.REACT_APP_DOLAPIKEY }
                });
                if (response.data && response.data.length > 0) {
                    setProjectDetails(response.data[0]);
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, [projectTitle, triggerFetch]);

    const handleCardClick = card => {
        if (card.type === "project") {
            if (projectTitle === card.title) {
                setTriggerFetch(!triggerFetch);
            } else {
                setProjectTitle(card.title);
            }
        } else if (card.type === "myhead") {
            openModal('bio');
        }
    };

    const getImagePath = (filename) => {
        if (!filename) return '';
        return require(`../assets/covers/${filename}`);
    };

    return (
        <ul className={`cards ${modalOpen ? 'cards__modal-open' : ''}`}>
            {jsonData.map((card, id) => (
                <li key={id} className="cards__projects" onClick={() => handleCardClick(card)}>
                    {card.type === 'project' && (
                        <img className="cards__projects-website" src={getImagePath(card.cover)} alt={card.title} />
                    )}
                    {card.type === 'letter' && (
                        <p className="cards__letter">{card.title}</p>
                    )}
                    {card.type === 'techno' && (
                        <img src={getImagePath(card.cover)} alt={card.title} />
                    )}
                    {card.type === 'myhead' && (
                        <img className="cards__projects-myhead" src={getImagePath(card.cover)} alt={card.title} />
                    )}
                </li>
            ))}
        </ul>
    );
}

export default Cards;
